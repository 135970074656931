function isElementInViewport(el) {
    if (typeof jQuery === "function" && el instanceof jQuery) {
        el = el[0];
    }

    if (!document.body.contains(el)) {
        return false;
    }

    var rect = el.getBoundingClientRect();

    if (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
            (window.innerWidth || document.documentElement.clientWidth)
    ) {
        // Element fits completely in viewport
        return "contain";
    } else if (
        rect.top <= 0 &&
        rect.left <= 0 &&
        rect.bottom >=
            (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right >=
            (window.innerWidth || document.documentElement.clientWidth)
    ) {
        // Viewport fits completely within element
        return "cover";
    } else if (
        rect.top >=
            (window.innerHeight || document.documentElement.clientHeight) ||
        rect.left >=
            (window.innerWidth || document.documentElement.clientWidth) ||
        rect.bottom <= 0 ||
        rect.right <= 0
    ) {
        // Any side is beyond opposite viewport limit (top side is below bottom, left side is beyond right, bottom is above top, or right is beyond left)
        return false;
    } else {
        // Only remaining option: some portion of element is within viewport, but not all of it
        return "partial";
    }
}
window.isElementInViewport = isElementInViewport;

(function () {
    document.querySelectorAll(".plane1").forEach(function (el) {
        // Completely outside the viewport
        if (!isElementInViewport(el)) {
            el.classList.remove("slide");
            // el.classList.add("slide");
        }
    });
})();
// Add classes when any part within viewport
var plane1 = function () {
    document.querySelectorAll(".plane1").forEach(function (el) {
        if (isElementInViewport(el)) {
            el.classList.add("slide");
            console.log("add class");
        }
    });
};
window.addEventListener("scroll", plane1);


(function () {
    document.querySelectorAll(".plane2").forEach(function (el) {
        // Completely outside the viewport
        if (!isElementInViewport(el)) {
            el.classList.remove("slide");
        }
    });
})();
// Add classes when any part within viewport
var plane2 = function () {
    document.querySelectorAll(".plane2").forEach(function (el) {
        if (isElementInViewport(el)) {
            el.classList.add("slide");
        }
    });
};
window.addEventListener("scroll", plane2);


(function () {
    document.querySelectorAll(".company-feature .wrapper").forEach(function (el) {
        // Completely outside the viewport
        if (!isElementInViewport(el)) {
            el.querySelectorAll(":scope .plane3").forEach(function (icon) {
                icon.classList.remove("slide");
            });
        }
    });
})();
// Add classes when any part within viewport
var plane3 = function () {
    document.querySelectorAll(".company-feature .wrapper").forEach(function (el) {
        if (isElementInViewport(el)) {
            el.querySelectorAll(":scope .plane3").forEach(function (icon) {
                icon.classList.add("slide");
            });
        }
    });
};
window.addEventListener("scroll", plane3);


(function () {
    document.querySelectorAll(".cost-of-travel .graph").forEach(function (el) {
        // Completely outside the viewport
        if (!isElementInViewport(el)) {
            el.querySelectorAll(":scope .bar").forEach(function (icon) {
                icon.classList.remove("slide");
            });
            el.querySelectorAll(":scope .airport").forEach(function (icon) {
                icon.classList.remove("appear");
            });
            el.querySelectorAll(":scope .price").forEach(function (icon) {
                icon.classList.remove("appear");
            });
        }
    });
})();
// Add classes when any part within viewport
var plane3 = function () {
    document.querySelectorAll(".cost-of-travel .graph").forEach(function (el) {
        if (isElementInViewport(el)) {
            el.querySelectorAll(":scope .bar").forEach(function (icon) {
                icon.classList.add("slide");
            });
            el.querySelectorAll(":scope .airport").forEach(function (icon) {
                icon.classList.add("appear");
            });
            el.querySelectorAll(":scope .price").forEach(function (icon) {
                icon.classList.add("appear");
            });
        }
    });
};
window.addEventListener("scroll", plane3);